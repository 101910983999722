import { initializeApp } from "firebase/app";
const initializeAuthentication = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyC9WSs6AsdlOuPZ6IBEDv_m98rrBjKQ198",
    authDomain: "grillzonwheelz-59b57.firebaseapp.com",
    projectId: "grillzonwheelz-59b57",
    storageBucket: "grillzonwheelz-59b57.firebasestorage.app",
    messagingSenderId: "35460245199",
    appId: "1:35460245199:web:ab673a5d84535a4bab39ba",
  };
  const app = initializeApp(firebaseConfig);
};

export default initializeAuthentication;
// apiKey: "AIzaSyDBYm9qXVci5mykJEyaBBQjiivApnQr0t0",
// authDomain: "tiktok-edc65.firebaseapp.com",
// projectId: "tiktok-edc65",
// storageBucket: "tiktok-edc65.appspot.com",
// messagingSenderId: "1057991155451",
// appId: "1:1057991155451:web:08b009b1a0a4610a599a5e"
