import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Admin.css"; // Import a CSS file for custom styles
import UseAuth from "../Context/UseAuth";

const FirstPage = () => {
  const { user } = UseAuth();
  console.log(user);

  let navigate = useNavigate();
  useEffect(() => {
    if (!user?.email) {
      navigate("/");
    }
  }, [user?.email, navigate]);

  const addFood = () => {
    navigate("/addFood073591048241998");
  };

  const deleteFood = () => {
    navigate("/deleteFood073591048241998");
  };

  return (
    <div className="first-page">
      <div className="button-container">
        <button className="btn-action btn-delete" onClick={deleteFood}>
          Delete/Update
        </button>

        <button className="btn-action btn-add" onClick={addFood}>
          Add Food
        </button>
      </div>

      <NavLink to="/" className="back-link">
        Back to Home Page
      </NavLink>
    </div>
  );
};

export default FirstPage;
