import React from "react";
import "./Home.css";

const DisplayFoodList = (props) => {
  const { image, foodName, foodPrice, description } = props.list;

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
      <div className="card h-100 food-card">
        <img
          src={image}
          className="img-fluid d-block"
          alt={foodName}
          style={{ width: "100%", height: "150px", objectFit: "cover" }}
        />
        <div className="card-body text-center">
          <h5 className="card-title">{foodName}</h5>
          <p className="card-text" style={{ wordWrap: "break-word" }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DisplayFoodList;
