import React, { useEffect } from "react";
import DeleteFood from "./DeleteFood";
import { useQuery } from "@tanstack/react-query";
import { NavLink, useNavigate } from "react-router-dom";
import UseAuth from "../../Context/UseAuth";

const LoadData = () => {
  const { user } = UseAuth();

  let navigate = useNavigate();
  useEffect(() => {
    if (!user?.email) {
      navigate("/");
    }
  }, [user?.email, navigate]);
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["repoData"],
    queryFn: () =>
      fetch("https://wheel-grilz.onrender.com/food").then((res) => res.json()),
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      <div className="ms-5 me-5">
        <div className="row">
          {data.map((list) => (
            <DeleteFood
              list={list}
              key={list._id}
              afterUpdate={() => refetch()}
            />
          ))}
        </div>
      </div>
      <NavLink to="/" href="" className="ms-5">
        {" "}
        back to home page
      </NavLink>
      <NavLink to="/admin073591048241998" href="" className="ms-5">
        {" "}
        back to admin page
      </NavLink>
    </div>
  );
};

export default LoadData;
