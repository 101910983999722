import React from "react";
import "./Footer.css";
import logo from "../../image/logo.png";
import UseAuth from "../Context/UseAuth";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { googleSignIn, user, Logout } = UseAuth(); // Destructure googleSignIn from context

  return (
    <footer className="footer mt-5">
      <div className="d-flex justify-content-center pt-2 pb-2">
        <div className="row justify-content-center">
          {/* Logo */}
          <img
            src={logo}
            alt="Logo"
            className="rounded-circle"
            style={{ width: "200px", height: "180px" }}
          />
          <p className="footer_text text-center">Grillz On Wheelz since 2013</p>
          <div className="d-flex justify-content-center">
            {user.email ? (
              <NavLink className="google-btn me-2" onClick={Logout}>
                Logout
              </NavLink>
            ) : (
              <NavLink className="google-btn me-2" onClick={googleSignIn}>
                Login
              </NavLink>
            )}
            {user.email === "shathil@hotmail.com" ||
            user.email === "mahed255261@gmail.com" ? (
              <NavLink to="admin073591048241998" className="google-btn">
                admin
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
